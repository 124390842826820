var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_basematterwrapper'),expression:"'mesh.add_basematterwrapper'"}],staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$bvModal.show(`new-base-matter-wrapper-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames( "mesh.basematterwrapper", false, "Envoltorio de Asignaturas" ))+" ")]):_vm._e()])]),_c('h3',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.basematterwrapper", false, "Envoltorio de Asignaturas" ))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.basematterwrapper',"allows_crud":_vm.allows_crud}})],1),_c('GenericBTable',{attrs:{"items":_vm.baseMattersWrapperList,"pagination":_vm.base_matters_wrapper.length,"fields":_vm.base_matters_wrapper_fields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"emitChangeSearchField":_vm.changeInputSearch},scopedSlots:_vm._u([{key:"cell(color)",fn:function(row){return [_c('div',{staticClass:"base-matter-wrapper-color",style:({
            'background-color': row.item.color,
            color: row.item.color,
          })})]}},{key:"cell(matters)",fn:function(row){return [_c('div',{staticClass:"campus-container"},_vm._l((_vm.getMatterName(row.item.matters)),function(item){return _c('div',{key:item.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]}},{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"d-flex"},[(_vm.allows_crud)?[_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_basematterwrapper'),expression:"'mesh.change_basematterwrapper'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Editar ${_vm.$getVisibleNames(
                  'mesh.basematterwrapper',
                  false,
                  'Envoltorio de Asignaturas'
                )}`
              ),expression:"\n                `Editar ${$getVisibleNames(\n                  'mesh.basematterwrapper',\n                  false,\n                  'Envoltorio de Asignaturas'\n                )}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-base-matter-wrapper-modal-${row.item.id}`)}}}),_c('button-delete',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_basematterwrapper'),expression:"'mesh.delete_basematterwrapper'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Eliminar ${_vm.$getVisibleNames(
                  'mesh.basematterwrapper',
                  false,
                  'Envoltorio de Asignaturas'
                )}`
              ),expression:"\n                `Eliminar ${$getVisibleNames(\n                  'mesh.basematterwrapper',\n                  false,\n                  'Envoltorio de Asignaturas'\n                )}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteBaseMatterWrapper(row.item.id)}}})]:_vm._e()],2),_c('b-modal',{attrs:{"id":`edit-base-matter-wrapper-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.basematterwrapper',
            false,
            'Envoltorio de Asignaturas'
          )}`,"size":"lg","hide-footer":""}},[_c('BaseMattersWrapperForm',{attrs:{"BaseMatterWrapper":row.item},on:{"updated":_vm.slotUpdatedBaseMatterWrapper}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":`new-base-matter-wrapper-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.basematterwrapper',
      false,
      'Envoltorio de Asignaturas'
    )}`,"size":"lg"}},[_c('BaseMattersWrapperForm',{on:{"created":_vm.slotCreatedBaseMatterWrapper}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }